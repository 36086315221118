import React, { useState } from "react";
import { MdMessage } from "react-icons/md";
import { Modal, Button, Form } from "react-bootstrap";
import "./chatbox.css";
import { FaPaperPlane } from "react-icons/fa";
import { app_api } from "../../atoms/api";

function ChatBox() {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: "Hi! 👋 How can I assist you today?",
      type: "received",
    },
  ]);
  const [message, setMessage] = useState("");
  async function sendMessage() {
    try {
      setMessages((prev) => [
        ...prev,
        {
          message,
          type: "sent",
        },
      ]);
      setMessage("");

      const res = await app_api.post("bot", {
        message,
      });
      setMessages((prev) => [
        ...prev,
        {
          message: res.data?.message?.content?.[0]?.text,
          type: "received",
        },
      ]);
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          message: "Sorry, I couldn't understand that.",
          type: "received",
        },
      ]);
    }
  }
  return (
    <div>
      <button
        onClick={() => setShowChat(!showChat)}
        style={{
          position: "fixed",
          zIndex: 1000,
          bottom: "120px",
          right: "20px",
          borderRadius: "50px",
          textAlign: "center",
          boxShadow: "2px 2px 3px #999",
          padding: "1.3rem",
          border: "none",
          backgroundColor: "#6bcacf",
        }}
      >
        <MdMessage
          style={{
            color: "#fff",
            fontSize: "2.5rem",
          }}
        />
      </button>
      <Modal
        show={showChat}
        onHide={() => setShowChat(!showChat)}
        className="chat-box-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Virtual Assistant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="chat-box-body">
            <div className="messages ">
              {messages.map((message, index) => {
                return (
                  <p key={index} className={`message ${message.type}`}>
                    {message.message}
                  </p>
                );
              })}
            </div>
            <Form onSubmit={(e) => {
              e.preventDefault()
              sendMessage()
            }}>
              <Form.Group controlId="formMessage" className="message-input">
                <Form.Control
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type a message..."
                />
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    sendMessage();
                  }}
                >
                  <FaPaperPlane
                    style={{
                      color: "#fff",
                    }}
                  />
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChatBox;
