import { Navigate, Outlet } from "react-router-dom";
import Cookie from "js-cookie";

export const ProtectedRoute = () => {
  if (!!Cookie.get("ut_user")) {
    return <Outlet />;
  } else {
    return <Navigate to="/login?go_back=true" />;
  }
};
