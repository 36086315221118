import React, { useState, useEffect, useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import pt from "prop-types";
import { Link, useNavigate, NavLink, Outlet } from "react-router-dom";
import Nav from "react-bootstrap/Navbar";
import NavLinkBootstrap from "react-bootstrap/NavLink";
import NavCollapse from "react-bootstrap/NavbarCollapse";
import Brand from "react-bootstrap/NavbarBrand";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import NavToggle from "react-bootstrap/NavbarToggle";
import { app_api } from "../../atoms/api";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";
import logo from "../../assets/images/logo.png";
import cart from "../../assets/images/cart.png";
import banner from "../../assets/images/banner.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../custom.scss";
import { BsWhatsapp } from "react-icons/bs";
import { ModalFooter } from "react-bootstrap";
import "animate.css";

import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ChatBox from "../../molecules/chatBot";

const LoginButton = styled.button`
  background: #f69494;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  padding: 14px 30px;
  ${"" /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */}
  &:hover {
    background-color: #0bb7af;
    border: none;
    box-shadow: 0px 10px 30px 0px rgb(166 223 226 / 20%);
    background: linear-gradient(120deg, #f69494 0%, #b1e3e5 100%);
    color: #fff !important;
  }
`;
const Cart = styled.button`
  background: transparent;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  padding: 12px 4px;
  ${"" /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */}
  ${
    "" /* &:hover {
        background-color:#0BB7AF;
        border:none;
        box-shadow:0px 10px 30px 0px rgb(166 223 226 / 20%);
        background:linear-gradient(120deg, #F69494 0%, #B1E3E5 100%);
        color:#fff!important;
        
} */
  }
`;

export const AppContainer = (props) => {
  const navigate = useNavigate();
    function activeWindow(e) {
    const active = document.querySelectorAll(".navbtn .activeNav");

    let activefilter = e.target.dataset.filter;
    active.forEach((values) => {
      values.classList.contains(activefilter)
        ? values.classList.add("processactive")
        : values.classList.remove("processactive");
    });
  }
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  const loggedIn = !!Cookies.get("ut_user") || !!Cookies.get("ut_school");
  const [user, setUser] = useState({});
  const [programs, setPrograms] = useState([]);
  const [pLoading, setPLoading] = useState(true);
  const [pError, setPError] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const href = window.location.pathname;

  useEffect(() => {
    if (loggedIn) {
      try {
        setUser(JSON.parse(Cookies.get("ut_user")));
      } catch {}
    }
  }, [user?.id]);

  useEffect(() => {
    app_api
      .get("programs/public")
      .then((res) => {
        setPrograms(res.data);
        setPLoading(false);
      })
      .catch((err) => {
        setPError(err?.response?.data?.message || "Error");
        setPLoading(false);
      });
  }, []);

  const subscribe = (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData(e.target);
    let values = {};
    formdata.forEach((value, key) => {
      values[key] = value;
    });
    app_api
      .post("/user/subscribe/newsletter", values)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  };

  return (
    <div>
      {user?.role === "learner" ||
      window.location.pathname === `/dashboard/learner` ? null : (
        <a
          href="https://wa.me/+919819457204?text=Hello+and+welcome+to+UnTaboo!+We+offer+age-appropriate%2C+engaging%2C+and+impactful+workshops+on+body+safety%2C+puberty%2C+sexual+literacy%2C+and+more+tailored+for+your+needs.+How+can+we+assist+you+today%3F"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsWhatsapp style={{ color: "#fff", fontSize: "45px" }} />
        </a>
      )}
      <Nav
        style={{
          backgroundColor: "#0f3d67",
          fontWeight: "600",
        }}
      >
        <Container className="col-10 mx-auto my-1">
          <div className="col-12 py-2 d-flex justify-content-between align-items-center">
            <div className="col-7 d-none d-lg-flex animate__animated animate__rubberBand animate__repeat-2	animate__delay-2s animate_slow">
              <img
                className="w-100 "
                src={banner}
                alt={"banner"}
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
              />
            </div>
            <div className="text-end col-4 d-lg-flex justify-content-end align-items-end">
              {loggedIn ? (
                <Link to="/logout">
                  <LoginButton className=" me-3">
                    <NavLinkBootstrap>
                      <NavLink
                        to="/logout"
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        Logout
                      </NavLink>
                    </NavLinkBootstrap>
                  </LoginButton>
                </Link>
              ) : (
                <Link to="/login">
                  <LoginButton className=" me-3">
                    <NavLinkBootstrap>
                      {href == "/schools" ? (
                        <NavLink
                          to="/login?auth=school"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          Login/SignUp
                        </NavLink>
                      ) : (
                        <NavLink
                          to="/login"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          Login/SignUp
                        </NavLink>
                      )}
                    </NavLinkBootstrap>
                  </LoginButton>
                </Link>
              )}
              {/* <Cart
                className="col-3 "
               
              >
                
                <div className="position-relative d-flex justify-content-around align-items-center">
                  <img className=" w-30 h-10" src={cart} alt={"cart"} />
                 
                  <p
                    className=" mb-0 position-absolute cart_number "
                    style={{ top: "-13px", left: "58px" }}
                  >
                    0
                  </p>
                </div>
              </Cart> */}
            </div>
          </div>
        </Container>
      </Nav>
      <Nav
        sticky="top"
        expand="md"
        variant="light"
        style={{
          backgroundColor: "#F69494",
          fontFamily: "Open Sans",
          fontWeight: "600",
        }}
      >
        <Container className="col-10 mx-auto my-1">
          <Brand className="w-30 w-sm-60">
            <img className="w-50 w-sm-50" src={logo} />
          </Brand>
          <NavToggle aria-controls="navbar-options" />
          <NavCollapse id="navbar-options" className="justify-content-md-end">
            <Nav
              style={{
                gap: "1rem",
              }}
              className="flex-column flex-md-row align-items-start  navbtn"
            >
              {loggedIn && (
                <NavLinkBootstrap
                  style={{
                    textDecoration: "none",
                    color: "#000000",
                    fontSize: "18px",
                  }}
                  href={!!user?.role ? `/dashboard/${user?.role}` : `/school`}
                >
                  Dashboard
                </NavLinkBootstrap>
              )}
              <NavLinkBootstrap
                data-filter="home"
                onClick={activeWindow}
                className="home navbar_style activeNav"
                style={{
                  textDecoration: "none",
                  color: "#000000",
                  fontSize: "18px",
                }}
                as={Link}
                to="/"
              >
                Home
              </NavLinkBootstrap>
              <NavDropdown
                onClick={activeWindow}
                renderMenuOnMount={true}
                className="about activeNav"
                title={
                  <span
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                      fontSize: "18px",
                    }}
                  >
                    About
                  </span>
                }
                color="#fff"
              >
                {/* <NavDropdown.Item
                  data-filter="about"
                  onClick={activeWindow}
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  as={Link}
                  to="/about#test"
                >
                  What We Do
                </NavDropdown.Item> */}

                <NavDropdown.Item
                  data-filter="about-whatwedo"
                  //  onClick={() => window.location.replace("/about/#knowUs") }
                  onClick={activeWindow}
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  href="/about#whatWeDo"
                >
                  What We Do
                </NavDropdown.Item>
                <NavDropdown.Item
                  data-filter="about"
                  //  onClick={() => window.location.replace("/about/#knowUs") }
                  onClick={activeWindow}
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  href="/about#knowUs"
                >
                  Get To Know Us
                </NavDropdown.Item>
                <NavDropdown.Item
                  data-filter="about"
                  onClick={activeWindow}
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  href="/about#memoryLane"
                >
                  Down Memory Lane
                </NavDropdown.Item>
                <NavDropdown.Item
                  data-filter="about"
                  onClick={activeWindow}
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  as={Link}
                  to="/resources-page"
                >
                  Resources
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                data-filter="programs"
                renderMenuOnMount={true}
                className="programs activeNav"
                title={
                  <span
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                      fontSize: "18px",
                    }}
                  >
                    Programs
                  </span>
                }
                color="#fff"
              >
                <NavDropdown.Item
                  data-filter="programs"
                  onClick={activeWindow}
                  as={Link}
                  to="/programs"
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  Puberty Adventure
                </NavDropdown.Item>
                {pLoading ? (
                  <NavDropdown.Item
                    data-filter="programs"
                    onClick={activeWindow}
                    style={{
                      fontFamily: "poppins",
                      textDecoration: "none",
                      color: "#000",
                    }}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </NavDropdown.Item>
                ) : pError !== null ? (
                  <NavDropdown.Item disabled>{pError}</NavDropdown.Item>
                ) : (
                  programs.map((p, index) => (
                    <NavDropdown.Item
                      data-filter="programs"
                      onClick={activeWindow}
                      key={index}
                      as={Link}
                      to={`/program/${p?.slug}`}
                      className="navhover"
                    >
                      {p?.name}
                    </NavDropdown.Item>
                  ))
                )}

                {/* <NavDropdown.Item className="navhover"  as={Link}
                to="/program/puberty-adventure">
                  Puberty Adventure
                </NavDropdown.Item> */}
                <NavDropdown.Item className="navhover" as={Link} to="/contact">
                  Express Interest
                </NavDropdown.Item>
              </NavDropdown>
              <NavLinkBootstrap
                data-filter="schools"
                className="navbar_style schools activeNav"
                onClick={activeWindow}
                style={{
                  textDecoration: "none",
                  color: "#000000",
                  fontSize: "18px",
                }}
                as={Link}
                to="/schools"
              >
                Schools
              </NavLinkBootstrap>

              <NavDropdown
                renderMenuOnMount={true}
                className="partnership activeNav"
                title={
                  <span
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                      fontSize: "18px",
                    }}
                  >
                    Partnerships
                  </span>
                }
                color="#fff"
              >
                <NavDropdown.Item
                  data-filter="partnership"
                  onClick={activeWindow}
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  as={Link}
                  to="/affiliate"
                >
                  Be an Affiliate
                </NavDropdown.Item>

                <NavDropdown.Item
                  data-filter="partnership"
                  onClick={activeWindow}
                  style={{
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                  }}
                  as={Link}
                  to="/affiliate/checkaffiliate"
                >
                  Affiliate Login
                </NavDropdown.Item>
              </NavDropdown>

              <NavLinkBootstrap
                as={Link}
                to="/contact"
                onClick={activeWindow}
                data-filter="contact"
                className="contact activeNav"
                style={{
                  textDecoration: "none",
                  color: "#000000",
                  fontSize: "18px",
                }}
              >
                Contact
              </NavLinkBootstrap>
              {/* {loggedIn ? (
                <Button className="py-0">
                  <NavLinkBootstrap>
                    <NavLink
                      to="/logout"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Logout
                    </NavLink>
                  </NavLinkBootstrap>
                </Button>
              ) : (
                <Button className="py-0">
                  <NavLinkBootstrap>
                    <NavLink
                      to="/login"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Login/SignUp
                    </NavLink>
                  </NavLinkBootstrap>
                </Button>
              )} */}
            </Nav>
          </NavCollapse>
        </Container>
      </Nav>
    {loggedIn && <ChatBox />}
      <Outlet />
      <div
        className="footer_bg_img poppins"
        style={{ backgroundColor: "#6BCAD0" }}
      >
        <Container>
          {window.location.pathname === `/dashboard/learner` ? null : (
            <>
              <div className="col-lg-5 text-center text-lg-start  px-2 px-lg-0 col-12 pt-10">
                <h3
                  className="mt-5 mb-4 secondary-blue  Yanone_Kaffeesatz-700"
                  style={{
                    lineHeight: "1.222",
                    fontSize: "55px",
                    color: "#26547C",
                  }}
                >
                  Join the UnTaboo family
                </h3>
                <p style={{ fontSize: "20px", color: "white" }}>
                  Get expert tips & free invites to webinars delivered right to
                  your mailbox
                </p>
              </div>
              <div className="flex w-full justify-center justify-lg-start ">
              <form onSubmit={subscribe} className="col-12 col-md-8 col-lg-6">
                <div className="mt-4 col-lg-10 col-12 px-2">
                  <div
                    className="form-group mb-3 col-12"
                    style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
                  >
                    <input
                      style={{
                        height: "60px",
                        background: "#fff",
                        border: "none",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        float: "left",
                        padding: "30px",
                        width: "60%",
                      }}
                      type="email"
                      className="form-control"
                      placeholder="Enter your mail"
                      name="email"
                      required
                    ></input>
                    <button
                      style={{
                        backgroundColor: "#F69494",
                        border: "none",
                        cursor: "pointer",
                        color: "#000",
                        height: "60px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        width: "40%",
                      }}
                      className="btn btn-outline-secondary"
                      type="submit"
                      id="button-addon2"
                    >
                      {loading ? "Signing up" : "Sign up"}
                    </button>
                  </div>
                  {success && (
                    <span className="text-secondary">Successfully added</span>
                  )}
                  {error !== null && (
                    <span className="text-danger">{error}</span>
                  )}
                </div>
              </form>
              </div>
            </>
          )}

          <div className="col-12 mx-auto d-lg-flex flex-wrap pt-lg-11">
            <div className="pt-lg-11  mt-3e  col-lg-5 col-12 pb-5 text-center text-lg-start">
              <img className="w-40" src={logo} alt={"footerLogo"} />
              <h4 className="fs-6 mt-4 mb-3" style={{ color: "#ffffff" }}>
                UnTaboo Education Pvt. Ltd
              </h4>
              <div className="col-6 mx-auto mx-lg-0 d-flex justify-content-between">
                <div className="footer-icons d-flex align-items-center  justify-content-center text-center">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/untabooers"
                    className=" d-flex align-items-center  justify-content-center text-center"
                  >
                    <FaFacebook style={{ fontSize: "20px" }} />
                  </a>
                </div>
                <div className="footer-icons d-flex align-items-center justify-content-center text-center">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/untaboo"
                    className=" d-flex align-items-center  justify-content-center text-center"
                  >
                    <FaInstagram style={{ fontSize: "20px" }} />
                  </a>
                </div>
                <div className="footer-icons d-flex align-items-center justify-content-center text-center">
                  <a
                    target="_blank"
                    href="https://twitter.com/untabooers?lang=en"
                    className=" d-flex align-items-center  justify-content-center text-center"
                  >
                    <FaTwitter style={{ fontSize: "20px" }} />
                  </a>
                </div>
                <div className="footer-icons d-flex align-items-center justify-content-center text-center">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/untaboo-a-sex-education-company/?original_referer=https%3A%2F%2Funtaboo.online%2F "
                    className=" d-flex align-items-center  justify-content-center text-center"
                  >
                    <FaLinkedinIn style={{ fontSize: "20px" }} />
                  </a>
                </div>
              </div>
            </div>

            <div className=" col-lg-4 pt-lg-11 col-12 text-white text-center text-lg-start p-11">
              <h4 style={{ padding: "0.4rem 1rem", fontSize: "25px" }}>
                Quick Links
              </h4>
              <NavLinkBootstrap className="mt-lg-5 mt-3">
                <NavLink
                  to="/about"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: "20px",
                  }}
                >
                  About us
                </NavLink>
              </NavLinkBootstrap>
              <NavLinkBootstrap>
                <NavLink
                  to="/schools"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: "20px",
                  }}
                >
                  UnTaboo for Schools
                </NavLink>
              </NavLinkBootstrap>
              <NavLinkBootstrap>
                <NavLink
                  className="px-0"
                  onClick={() => window.open("/privacy-policy", "_blank")}
                  to="/privacy-policy"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    padding: "8px 0 8px 0!important",
                    fontSize: "20px",
                  }}
                >
                  Privacy Policy
                </NavLink>
              </NavLinkBootstrap>

              <NavLinkBootstrap>
                <NavLink
                  onClick={() => window.open("/terms-and-conditions", "_blank")}
                  to="/terms-and-conditions"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: "20px",
                  }}
                >
                  Terms and Conditions
                </NavLink>
              </NavLinkBootstrap>
              <NavLinkBootstrap>
                <NavLink
                  onClick={() =>
                    window.open("/cancellation-and-refund-policy", "_blank")
                  }
                  to="/cancellation-and-refund-policy"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: "20px",
                  }}
                >
                  Cancellation/Refund Policy
                </NavLink>
              </NavLinkBootstrap>
            </div>
            <div className="col-lg-3 text-white text-center text-lg-start pt-lg-11">
              <h4
                style={{ padding: "0.4rem 1rem", fontSize: "25px" }}
                className="mt-3e mt-lg-0"
              >
                Contact Us
              </h4>
              <p
                className="mt-lg-5 mt-3 ps-lg-3 text-light"
                style={{ color: "#fff!important", fontSize: "20px" }}
              >
                Email:{" "}
                <a
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "20px",
                  }}
                  href="mailto:team.untaboo@gmail.com"
                >
                  team.untaboo@gmail.com
                </a>
              </p>
              <p
                className="mt-lg-3 mt-3 ps-lg-3 text-light"
                style={{ color: "#fff!important", fontSize: "20px" }}
              >
                {" "}
                Mob No.: <br></br>
                <a
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "20px",
                  }}
                  href="tel:+919819457204"
                >
                  +919819457204
                </a>
              </p>
              <p
                className="mt-lg-3 mt-3 ps-lg-3 text-light"
                style={{ color: "#fff!important", fontSize: "20px" }}
              >
                Address: <br></br>RPCHS Juhu, Mumbai 400049
              </p>
            </div>
            <br></br>
            <div className="mt-2 col-12 text-center ">
              <p className="text-white mb-lg-4 mb-0 pb-4 pb-lg-0">
                Copyright ©2022 All rights reserved by
                <span className="primary-color">
                  {" "}
                  UnTaboo Education Pvt. Ltd.
                </span>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
