import { Navigate, Outlet } from "react-router-dom";
import Cookie from "js-cookie";

export const SchoolProtectedRoute = () => {
  if (!!Cookie.get("ut_school")) {
    return <Outlet />;
  } else {
    return <Navigate to="/login?go_back=true&auth=school" />;
  }
};
